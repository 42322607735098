export const EVENTS_APP_DEF_ID = '140603ad-af8d-84a5-2c80-a0f60cb47351'
export const MEMBERS_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9'

export const BASE_URL = 'https://www.wix.com'

export const MEMBERS_DEPENDANT_APPS: { [appname: string]: any } = {
  notifications: {
    appDefinitionId: '14f25924-5664-31b2-9568-f9c5ed98c9b1',
    pageId: 'notifications_app'
  },
  settings: {
    appDefinitionId: '14f25dc5-6af3-5420-9568-f9c5ed98c9b1',
    pageId: 'settings'
  }
}

export const NOTIFICATIONS_PAGE = {
  numbers: {
    key: 'notificationsCount',
    default: 0
  },
  appDefinitionId: MEMBERS_DEPENDANT_APPS.notifications.appDefinitionId,
  pageId: MEMBERS_DEPENDANT_APPS.notifications.pageId,
  method: 'addApplication',
  menuOrder: 4,
  social: false,
  urlOverride: 'notifications'
}

export const SETTINGS_PANEL = {
  appDefinitionId: MEMBERS_DEPENDANT_APPS.settings.appDefinitionId,
  pageId: MEMBERS_DEPENDANT_APPS.settings.pageId,
  method: 'addApplication',
  menuOrder: 4,
  social: false,
  urlOverride: 'settings'
}
