import {
  EVENTS_APP_DEF_ID,
  MEMBERS_APP_DEF_ID,
  MEMBERS_DEPENDANT_APPS,
  NOTIFICATIONS_PAGE,
  SETTINGS_PANEL
} from './constants'
import {fetchExperiments} from './experiments'

let sdk: any
let appToken: string
let membersEnabled = false

export const editorReady = async (
  editorSDK: any,
  token: string
): Promise<any> => {
  sdk = editorSDK
  appToken = token

  const experiments = await fetchExperiments()
  membersEnabled = experiments.enabled('specs.events.ui.MembersArea')

  if (membersEnabled) {
    await setDependantApps()
  }
}

export const handleAction = async (args: any) => {
  const {type, payload} = args
  switch (type) {
    case 'appInstalled':
      switch (payload.appDefinitionId) {
        case EVENTS_APP_DEF_ID:
          if (membersEnabled) {
            await sdk.application.install(appToken, {
              appDefinitionId: MEMBERS_APP_DEF_ID,
              initiatorAppDefinitionId: EVENTS_APP_DEF_ID
            })
          }
          return
        case MEMBERS_APP_DEF_ID:
          if (membersEnabled) {
            const isInstalled = await sdk.tpa.isApplicationInstalled(appToken, {
              appDefinitionId: EVENTS_APP_DEF_ID
            })
            if (isInstalled) {
              await installMembersAreaSections()
            }
          }
          return
        default:
          return
      }
    default:
      console.log(type, payload)
      return
  }
}

const withDefaultPageProps = (page: any) => {
  return {
    numbers: {},
    showInLoginMenu: false,
    showInMemberMenu: true,
    social: true,
    socialHome: false,
    urlOverride: null,
    ...page
  }
}

const getMembersApi = () =>
  sdk.application.getPublicAPI(appToken, {
    appDefinitionId: MEMBERS_APP_DEF_ID
  })

const setDependantApps = async () => {
  const membersAPI = await getMembersApi()
  const dependencies = Object.keys(MEMBERS_DEPENDANT_APPS).map(function(app) {
    return MEMBERS_DEPENDANT_APPS[app].appDefinitionId
  })
  return membersAPI &&
    membersAPI.setDependantApps({
      appId: EVENTS_APP_DEF_ID,
      dependencies
    })
}

const installMembersAreaSections = async () => {
  const membersAPI = await getMembersApi()
  await membersAPI.addApplications([
    withDefaultPageProps(NOTIFICATIONS_PAGE),
    withDefaultPageProps(SETTINGS_PANEL)
  ])
  await setDependantApps()
}

export const getAppManifest = () => ({})
export const getControllerPresets = async () => []
